import { Loader } from 'google-maps';
import churchIcon from '/src/img/marker_church.svg';
import venueIcon from '/src/img/marker_venue.svg';
import '/src/js/photoDeck';

const weddingDateObj = {
    year: 2023,
    month: 7,
    day: 26,
    hours: 19,
    minutes: 0
}

$(document).ready(function () {

    /***************** Dev ribbon ******************/
    // if (window.location.hostname !== "www.iandm.wedding") {
    //     $('.navigation').addClass('dev');
    // }

    /***************** Waypoints ******************/
    $('.wp0').waypoint(function () {
        $('.wp0').addClass('animated fadeInDown');
    }, {
        offset: '75%'
    });
    $('.wp1').waypoint(function () {
        $('.wp1').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp2').waypoint(function () {
        $('.wp2').addClass('animated zoomIn');
    }, {
        offset: '75%'
    });
    $('.wp3').waypoint(function () {
        $('.wp3').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });
    $('.wp4').waypoint(function () {
        $('.wp4').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp5').waypoint(function () {
        $('.wp5').addClass('animated zoomIn');
    }, {
        offset: '75%'
    });
    $('.wp6').waypoint(function () {
        $('.wp6').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });
    $('.wp7').waypoint(function () {
        $('.wp7').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp7-1').waypoint(function () {
        $('.wp7-1').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp8').waypoint(function () {
        $('.wp8').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });
    $('.wp8-1').waypoint(function () {
        $('.wp8-1').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });
    $('.wp9').waypoint(function () {
        $('.wp9').addClass('animated fadeInUp');
    }, {
        offset: '75%'
    });
    $('.wp10').waypoint(function () {
        $('#swipe-helper-overlay > i').animateCss('fadeInUp', 'wobble', 'zoomOutRight', 'hidden');
    }, {
        offset: '70%'
    });

    /***************** Initiate Flexslider ******************/
    $('.flexslider').flexslider({
        animation: "slide"
    });

    /***************** Tooltips ******************/
    $('[data-toggle="tooltip"]').tooltip();

    /***************** Nav Transformicon ******************/
    /* When user clicks the Icon */
    $('.nav-toggle').click(function () {
        $(this).toggleClass('active');
        $('.header-nav').toggleClass('open');
        event.preventDefault();
    });
    /* When user clicks a link */
    $('.header-nav li a').click(function () {
        $('.nav-toggle').toggleClass('active');
        $('.header-nav').toggleClass('open');

    });

    /***************** Header BG Scroll ******************/
    $(function () {
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 20) {
                $('section.navigation').addClass('fixed');
                $('header').css({
                    "border-bottom": "none",
                    "padding": "20px 0"
                });
                $('header .member-actions').css({
                    "top": "26px"
                });
                $('header .navicon').css({
                    "top": "34px"
                });
            } else {
                $('section.navigation').removeClass('fixed');
                $('header').css({
                    "border-bottom": "solid 1px rgba(255, 255, 255, 0.2)",
                    "padding": "50px 0 10px 0"
                });
                $('header .member-actions').css({
                    "top": "55px"
                });
                $('header .navicon').css({
                    "top": "48px"
                });
            }
        });
    });

    /***************** Smooth Scrolling ******************/
    $(function () {

        $('a[href*=#]:not([href=#])').click(function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {

                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 90
                    }, 2000);
                    return false;
                }
            }
        });

    });

    /***************** Countdown timer ******************/
    var today = new Date(new Date().setHours(0, 0, 0, 0));
    var weddingDate = new Date(weddingDateObj.year, weddingDateObj.month, weddingDateObj.day, weddingDateObj.hours, weddingDateObj.minutes);
    var remDays = Math.round((weddingDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
    $('.eventDate').html(weddingDate.toLocaleDateString());
    $('#churchTime').html(weddingDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));
    var venueTime = new Date(new Date(weddingDate).setHours(weddingDate.getHours() + 1));
    $('#venueTime').html(venueTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));
    var lastNotice = new Date(weddingDate - (10 * (24*60*60*1000)));
    $('#lastNotice').html(lastNotice.toLocaleDateString());
    var ribbonRemDays = $('#countdown .ribbon-rem-days');
    var ribbonFirstLine = $('#countdown .ribbon-first-line');
    var ribbonSecondLine = $('#countdown .ribbon-second-line');
    if (remDays > 0) {
        ribbonRemDays.html(remDays);
        ribbonFirstLine.html(remDays > 1 ? 'ΗΜΕΡΕΣ' : 'ΗΜΕΡΑ');
    } else if (remDays == 0) {
        ribbonRemDays.html('💍');
        ribbonFirstLine.html('ΣΗΜΕΡΑ');
        ribbonSecondLine.html('ΣΑΣ ΠΕΡΙΜΕΝΟΥΜΕ!')
    }

    /************* Hide elements after the wedding date **************/
    if (remDays < 0) {
        $('#countdown').hide();
        $('ul.member-actions > li:nth-child(2)').css({ visibility: "hidden" });
        $('section.hero > div.container > div:nth-child(2)').css({ visibility: "hidden" });
        $('div.down-arrow.floating-arrow > a').attr('href', '#intro');
        $('#rsvp').hide();
    }

    /********************** Embed youtube video *********************/
    $('.player').YTPlayer({
        videoURL: 'zvJl1I3-imQ',
        containment: '#video-bg',
        autoPlay: true,
        mute: true,
        showControls: false,
        startAt: 14,
        stopAt: 83,
        opacity: 1,
        quality: 'small'
    });


    /********************** Toggle Map Content **********************/
    $('#btn-show-map').click(function () {
        $('#map-content').toggleClass('toggle-map-content');
        $('#btn-show-content').toggleClass('toggle-map-content');
    });
    $('#btn-show-content').click(function () {
        $('#map-content').toggleClass('toggle-map-content');
        $('#btn-show-content').toggleClass('toggle-map-content');
    });

    /********************** Add to Calendar **********************/
    var myCalendar = createCalendar({
        options: {
            class: '',
            // You can pass an ID. If you don't, one will be generated for you
            id: ''
        },
        data: {
            // Event title
            title: "Γάμος Ισίδωρου και Μαριάντζελας",

            // Event start date
            start: weddingDate,

            // Event duration (IN MINUTES)
            duration: 720,

            // You can also choose to set an end time
            // If an end time is set, this will take precedence over duration
            // end: new Date('Aug 01, 2020 07:00'),

            // Event Address
            address: 'Ναός Αγίας Μαρίας Μαγδαληνής, Δαγκλή 8, Χανιά 731 33, Ελλάδα',

            // Event Description
            description: "Εκκλησία: Ναός Αγίας Μαρίας Μαγδαληνής, Δαγκλή 8 (Eντός Κήπου Βασιλόπαιδος Μαρίας), Χανιά \n\nΔεξίωση: Πορτοκάλι Hall, Γέφυρα Πλατανιά, Χανιά \n\nΠληροφορίες: www.envite.gr"
        }
    });

    $('#add-to-cal').html(myCalendar);


    /********************** RSVP **********************/
    // var RSVPURL = 'https://script.google.com/macros/s/AKfycbwQMa2mzF9l_QWbYFfiXxnMZxYmu5136X4XotVFmBZMHRhI87vhXp-pUN5y5OvOsoyv/exec';
    checkLocalStorageAndSetRsvpSection();

    // Change visibility of guests and places based on rsvp answer (Yes/No)
    $('#rsvp-form input[name=rsvpAnswer]').on('change', function () {
        if ($('#rsvp-form input[name=rsvpAnswer]:checked').val() == "Yes") {
            $('#rsvp-form .onlyOnPositiveAnswer').removeClass('hidden');
            $('#rsvp-form input[name=guests]').prop("required", true);
        } else {
            $('#rsvp-form .onlyOnPositiveAnswer').addClass('hidden');
            $('#rsvp-form input[name=guests]').prop("required", false);
        }
    });

    // Handle on rsvp-form submission
    $('#rsvp-form').on('submit', function (e) {
        e.preventDefault();
        var form = this;
        $('#alert-wrapper').html('');

        // Check if rsvpAnswer is yes and no place has been selected
        if ($('#rsvp-form input[name=rsvpAnswer]:checked').val() == "Yes" &&
            !$('#rsvpPlaceAnswerChurch').prop('checked') &&
            !$('#rsvpPlaceAnswerVenue').prop('checked')) {
            $('#alert-wrapper').html(alert_markup('info', 'Παρακαλούμε επιλέξτε τα μέρη που θα παρευρεθείτε'));
            return;
        }

        // Check invite code
        // if (MD5($('#invite_code').val()) !== '9f0edfe7687ede8fb75060b46546331a') {
        //     $('#alert-wrapper').html(alert_markup('danger', 'Ο κωδικός πρόσκλησης είναι λάθος'));
        //     return;
        // }

        // Check if telephone already exists in db and prevent posting a new one.
        $('#alert-wrapper').html(alert_markup('info', '<strong>Μια στιγμή!</strong> Αποθηκεύουμε την απάντησή σας'));

        // $.get(RSVPURL, { tel: $('#rsvp-form input[name=tel]').val() }, function (data) {
        //     if (data.length > 0) {
        //         localStorage.setItem('iandmguest', JSON.stringify(data[0]));
        //         $('#rsvp-modal p').html('Μας έχετε ήδη απαντήσει στο παρελθόν!<br>Αν θέλετε να αλλάξετε την απάντησή σας επικοινωνήστε μαζί μας με email στο <a href="mailto:iandm@iandm.wedding">iandm@iandm.wedding</a> ή στα τηλέφωνα <a href="tel:6976401650">6976401650</a> (Ισίδωρος), <a href="tel:6941423079">6941423079</a> (Μαριάντζελα)');
        //         $('#rsvp-modal').modal('show');
        //         checkLocalStorageAndSetRsvpSection();
        //     } else {
        //         // Proceed with storing the new rsvp answer
        //         var data = $(form).serialize();

        //         $.post(RSVPURL, data)
        //             .done(function (data) {
        //                 console.log(data);
        //                 // Clear any alerts
        //                 $('#alert-wrapper').html('');
        //                 // Prepare and show the modal
        //                 if ($('#rsvp-form input[name=rsvpAnswer]:checked').val() == "Yes") {
        //                     $('#rsvp-modal p').html('Ανυπομονούμε να σας δούμε στο γάμο μας!');
        //                 } else {
        //                     $('#rsvp-modal p').html('Θα θέλαμε πολύ να σας δούμε στο γάμο μας αλλά καταλαβαίνουμε!');
        //                 }
        //                 $('#rsvp-modal').modal('show');
        //                 // Set localstorage, hide form and display only the response
        //                 localStorage.setItem('iandmguest', data.data);
        //                 checkLocalStorageAndSetRsvpSection();
        //             })
        //             .fail(function (data) {
        //                 console.log(data);
        //                 $('#alert-wrapper').html(alert_markup('danger', 'Αυτή τη στιγμή υπάρχει κάποιο πρόβλημα. Δοκιμάστε πάλι αργότερα!'));
        //             });
        //     }
        // })
        //     .fail(function (data) {
        //         console.log(data);
        //         $('#alert-wrapper').html(alert_markup('danger', 'Αυτή τη στιγμή υπάρχει κάποιο πρόβλημα. Δοκιμάστε πάλι αργότερα!'));
        //         return;
        //     });

        // Demo rsvp
        setTimeout(() => {
            // const data = {
            //     "result": "success",
            //     "data": "{\"name\":[\"ISIDOROS K\"],\"tel\":[\"6976401650\"],\"invite_code\":[\"1\"],\"rsvpAnswer\":[\"No\"],\"guests\":[\"\"]}"
            // }
            // const data = {
            //     "result": "success",
            //     "data": "{\"name\":[\"ISIDOROS K\"],\"tel\":[\"6976401650\"],\"invite_code\":[\"1\"],\"rsvpAnswer\":[\"Yes\"],\"rsvpPlaceAnswer\":[\"Εκκλησία\",\"Δεξίωση\"],\"guests\":[\"2\"]}"
            // }
            const rsvpAnswer = $('[name="rsvpAnswer"]')[0].checked ? $('[name="rsvpAnswer"]')[0].value : $('[name="rsvpAnswer"]')[1].value
            const church = ($('#rsvpPlaceAnswerChurch')[0].checked ? $('#rsvpPlaceAnswerChurch').val() : "")
            const venue = ($('#rsvpPlaceAnswerVenue')[0].checked ? $('#rsvpPlaceAnswerVenue').val() : "")
            const data = {
                "result": "success",
                "data": "{\"name\":[\"" + $('[name="name"]').val() + "\"]," +
                    "\"tel\":[\"" + $('[name="tel"]').val() + "\"]," +
                    "\"invite_code\":[\"" + $('[name="invite_code"]').val() + "\"]," +
                    "\"rsvpAnswer\":[\"" + rsvpAnswer + "\"]," +
                    "\"rsvpPlaceAnswer\":[\"" + church + " " + venue + "\"]," +
                    "\"guests\":[\"" + $('[name="guests"]').val() + "\"]}"
            }
            // Clear any alerts
            $('#alert-wrapper').html('');
            // Prepare and show the modal
            if ($('#rsvp-form input[name=rsvpAnswer]:checked').val() == "Yes") {
                $('#rsvp-modal p').html('Ανυπομονούμε να σας δούμε στο γάμο μας!');
            } else {
                $('#rsvp-modal p').html('Θα θέλαμε πολύ να σας δούμε στο γάμο μας αλλά καταλαβαίνουμε!');
            }
            $('#rsvp-modal').modal('show');
            // Set localstorage, hide form and display only the response
            localStorage.setItem('iandmguest', data.data);
            checkLocalStorageAndSetRsvpSection();
        }, 1000);

    });

    // /********************** Web App **********************/
    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker
    //         .register('/sw.js')
    //         .then(function (reg) { console.log('SW Registration succeeded. Scope is ' + reg.scope); })
    //         .catch(function error() { console.log('SW Registration failed with ' + error); });
    // }

});

/********************** Extras **********************/
// chain animations
(function ($) {
    $.fn.animateCss = function () {

        function trigger(index, $elem, args) {
            var animClass = args[index];

            if (animClass == 'hidden') {
                $elem.parent().addClass('hidden');
            } else {
                $elem
                    .css('-webkit-animation-delay:', '0.0s')
                    .css('animation-delay:', '0.0s')
                    .addClass('animated ' + animClass)
                    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
                        $(e.target).removeClass('animated ' + animClass);

                        if (index + 1 < args.length) {
                            trigger(index + 1, $elem, args);
                        }
                    });
            }
        }

        trigger(0, this, arguments);

        return this;
    };
}(jQuery));

// Checks if there is guest data in local storage and updates RSVP section
function checkLocalStorageAndSetRsvpSection() {
    // Check if the guest has responded in the past
    var guestData = JSON.parse(localStorage.getItem('iandmguest'));
    if (guestData) {
        // Add data to response table
        $('#rsvp-response-name > td:nth-child(2)').html(guestData.name);
        $('#rsvp-response-tel > td:nth-child(2)').html(guestData.tel);
        var rsvpResponseAnswer = '';
        if (guestData.rsvpAnswer == 'No') {
            rsvpResponseAnswer = 'Δε θα παρευρίσκομαι';
            $('#rsvp-response-guests').hide();
            $('#add-to-cal').hide();
        } else {
            rsvpResponseAnswer = 'Θα παρευρίσκομαι σε ' + guestData.rsvpPlaceAnswer;
        }
        $('#rsvp-response-answer > td:nth-child(2)').html(rsvpResponseAnswer);
        $('#rsvp-response-guests > td:nth-child(2)').html(guestData.guests);
        // show only response table
        $('#rsvp-response').removeClass('hidden');
        $('#rsvp-form').addClass('hidden');
    } else {
        // show only rsvp form
        $('#rsvp-form').removeClass('hidden');
        $('#rsvp-response').addClass('hidden');
    }
}

// Google maps
const loader = new Loader('AIzaSyDtWkhrsEHx9r3Ydlgdqr011wfKJnMZooo');

loader.load().then(function (google) {
    const map = new google.maps.Map(document.getElementById('map-canvas'), {
        center: { lat: 35.5155409, lng: 23.9630733 },
        zoom: 11,
        scrollwheel: false
    });

    const markerChurch = new google.maps.Marker({
        position: new google.maps.LatLng(35.517495, 24.038191),
        icon: churchIcon,
        map: map
    });

    const markerVenue = new google.maps.Marker({
        position: new google.maps.LatLng(35.514989, 23.888355),
        icon: venueIcon,
        map: map
    });

    markerChurch.addListener('click', function () {
        $('#map-content').toggleClass('toggle-map-content');
        $('#btn-show-content').toggleClass('toggle-map-content');
    });

    markerVenue.addListener('click', function () {
        $('#map-content').toggleClass('toggle-map-content');
        $('#btn-show-content').toggleClass('toggle-map-content');
    });

});

// alert_markup
function alert_markup(alert_type, msg) {
    return '<div class="alert alert-' + alert_type + '" role="alert">' + msg + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span>&times;</span></button></div>';
}

// MD5 Encoding
var MD5 = function (string) {

    function RotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    }

    function AddUnsigned(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
            } else {
                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
            }
        } else {
            return (lResult ^ lX8 ^ lY8);
        }
    }

    function F(x, y, z) {
        return (x & y) | ((~x) & z);
    }

    function G(x, y, z) {
        return (x & z) | (y & (~z));
    }

    function H(x, y, z) {
        return (x ^ y ^ z);
    }

    function I(x, y, z) {
        return (y ^ (x | (~z)));
    }

    function FF(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    function GG(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    function HH(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    function II(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    function ConvertToWordArray(string) {
        var lWordCount;
        var lMessageLength = string.length;
        var lNumberOfWords_temp1 = lMessageLength + 8;
        var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        var lWordArray = Array(lNumberOfWords - 1);
        var lBytePosition = 0;
        var lByteCount = 0;
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
            lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    };

    function WordToHex(lValue) {
        var WordToHexValue = "", WordToHexValue_temp = "", lByte, lCount;
        for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255;
            WordToHexValue_temp = "0" + lByte.toString(16);
            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
        }
        return WordToHexValue;
    };

    function Utf8Encode(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    };

    var x = Array();
    var k, AA, BB, CC, DD, a, b, c, d;
    var S11 = 7, S12 = 12, S13 = 17, S14 = 22;
    var S21 = 5, S22 = 9, S23 = 14, S24 = 20;
    var S31 = 4, S32 = 11, S33 = 16, S34 = 23;
    var S41 = 6, S42 = 10, S43 = 15, S44 = 21;

    string = Utf8Encode(string);

    x = ConvertToWordArray(string);

    a = 0x67452301;
    b = 0xEFCDAB89;
    c = 0x98BADCFE;
    d = 0x10325476;

    for (k = 0; k < x.length; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
        d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
        c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
        b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
        a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
        d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
        c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
        b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
        a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
        d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
        c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
        b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
        a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
        d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
        c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
        b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
        a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
        d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
        c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
        b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
        a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
        d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
        b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
        a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
        d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
        c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
        b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
        a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
        d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
        c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
        b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
        a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
        d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
        c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
        b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
        a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
        d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
        c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
        b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
        a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
        d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
        c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
        b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
        a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
        d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
        c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
        b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
        a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
        d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
        c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
        b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
        a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
        d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
        c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
        b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
        a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
        d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
        c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
        b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
        a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
        d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
        c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
        b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
        a = AddUnsigned(a, AA);
        b = AddUnsigned(b, BB);
        c = AddUnsigned(c, CC);
        d = AddUnsigned(d, DD);
    }

    var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

    return temp.toLowerCase();
};